

<template>
  <!--narrative -->
  <div class="flex" v-if="!loading">
    <CustomModal :fullscreen="true" height="h-screen" width="w-screen" :open="!!narrative" :bgColor="'bg-'+currentTheme+'-light'" :shadow="false" >
      <EnqueteNarrative @nextstep="getNextGame()" slide="top" :narrative="narrative[narrativeIndex]" v-if="narrative"></EnqueteNarrative>
    </CustomModal>
  </div>


  <!-- Hack Tailwind: don't remove if you like gradient -->
  <div class="hidden from-blue-quest from-yellow-quest from-pink-quest from-green-quest via-blue-light via-yellow-light via-green-quest-light via-pink-quest-light via-blue-quest-light via-yellow-quest-light"></div>
  <div v-if="courses && courses.length > 0" :class="`absolute flex h-screen w-screen text-yellow-light bg-cover bg-opacity-90 ${currentTheme}`" :style="customBg">
    <div :class="`h-screen w-screen flex text-yellow-light bg-gradient-to-r  from-${currentTheme} via-${currentTheme}-light via-${currentTheme}-light`">
      <transition :name="transitionname">
        <component v-bind:is="compo" @nextstep="getCompoInfos($event)" v-bind="compProperties" :key="compo"></component>
      </transition>
    </div>
  </div>
</template>

<script>

import EnqueteIntro from "./EnqueteIntro";
import EnqueteSelect from "./EnqueteSelect";
import EnqueteActs from "./EnqueteActs";

import EnqueteWelcome from "./EnqueteWelcome";
import EnqueteEnd from "./EnqueteEnd";
import {mapState} from "vuex";
import CustomModal from "../../components/CustomModal"
import EnqueteNarrative from "../../views/enquete/EnqueteNarrative"
import {CourseType} from "../../constants/game-config";
import {parseScenarios} from "../../utils/apollo-parser";

import { IsFirstLevelFinished } from '@/composables/user_log'

const {parseNarrativeFromId} = require("../../utils/apollo-parser");

const {difference} = require("lodash");

const initSteps = [
 'welcome', 'enqueteSelect', 'intro', 'actsintro',
]
let steps = Array.from(initSteps)


export default {
  components: {
    'welcome': EnqueteWelcome,
    'enqueteSelect': EnqueteSelect,
    'intro': EnqueteIntro,
    'narrative1': EnqueteNarrative,
    'narrative2': EnqueteNarrative,
    'narrative3': EnqueteNarrative,
    'narrative4': EnqueteNarrative,
    'narrative5': EnqueteNarrative,
    'narrative6': EnqueteNarrative,
    'narrative7': EnqueteNarrative,
    'narrative8': EnqueteNarrative,
    'narrative9': EnqueteNarrative,
     CustomModal,
     EnqueteNarrative,
    'narrative10': EnqueteNarrative,
    'actsintro': EnqueteActs,
    'enqueteEnd': EnqueteEnd,
  },
  props: {
    noIntro: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return{
      'transitionname': '',
      'compo': initSteps[0],
      'moduleIdSelected': undefined,
      'rattrapage': false,
      'win': false,
      'scenarRattrapage' : undefined,
      'loading': false,
      'introOpen': false,
      'narrative': undefined,
      'narrativeIndex':0
    }
  },
  watch: {
    noIntro: {
      handler(noIntro) {
        switch (noIntro) {
          case 'acts': {
            this.rattrapage = false
            this.compo = initSteps[1]

            // Show acts selection screen if possible
            const moduleId = this.$store.state.gamestatus.currentModule
            const module = (this.courses && this.courses.length > 0 && moduleId ? this.courses[0].modules.find( m => m.id === moduleId) : null)

            if (module && this.courses[0].type.slug == 'investigation') {
              this.moduleIdSelected = moduleId
              this.compo = initSteps[3]
            }
            break
          }

          case 'select':
            this.rattrapage = false
            this.compo = initSteps[1]
            break

          case 'rattrapage':
            this.rattrapage = true
            this.compo = initSteps[2]
            break

          case 'bravo':
            this.rattrapage = false
            this.compo = 'enqueteEnd'
            this.win = true
            break
          case 'aie':
            this.rattrapage = true
            this.compo = 'enqueteEnd'
            this.win = false
            break
        }
      },
      immediate: true
    }
  },
  async mounted() {
    await this.$store.dispatch('course/getCourses', CourseType.MISSION )
    if (!IsFirstLevelFinished()) {
      this.$router.push({ name: 'Home', params: { bypass: 'enquete' } })
      return
    }
    await this.$store.dispatch('course/getCourses', CourseType.QUEST )
    if(!this.introopen && this.courses[0].metas['narrator_intro']) {
      const [narrative] = await Promise.all([parseNarrativeFromId(this.courses[0].metas['narrator_intro'])]);
      this.narrative = narrative
    }else{
      this.$store.dispatch('gamestatus/setIntroNiv2Done' , true)
    }
  },
  methods: {
    getNextGame(){
      if(this.narrative.length === this.narrativeIndex+1){
        this.narrative = false
        this.$store.dispatch('gamestatus/setIntroNiv2Done', true)
      }else{
        this.narrativeIndex++
      }

    },
    async getCompoInfos(val) {
      if(val.theme){
        this.$store.dispatch('setTheme', val.theme)
      }
      if(val.moduleId){
        this.moduleIdSelected = val.moduleId;
        await this.$store.dispatch('enquete/setEnquete', val.moduleId)
        await this.$store.dispatch('gamestatus/setCurrentCourseType', CourseType.QUEST)

        await this.getScenarioRattrapage()
        let nodes = this.scenarRattrapage.nodes
        nodes = Array.from(nodes).reverse().filter(v => v.type.slug === 'activity')[0]
        if(this.activitiesFinished.includes(nodes.node_content)){
          this.$store.dispatch('gamestatus/setCurrentRattrapageDone', true)
          this.win = true
        }else{
          this.$store.dispatch('gamestatus/setCurrentRattrapageDone', false)
        }
        if(this.enquete.narrativeIntro.length > 0){
          const arr = []
          steps = [...initSteps]

          for (let i=0; i < this.enquete.narrativeIntro.length; i++){
            arr.push(`narrative${i+1}`)
          }
          steps.splice(steps.indexOf('actsintro'), 0,...arr )
        }
      }
     /* if ((this.getNextSlide === 'actsintro' && this.isQuestDone) && val.action === 'next') {
        this.compo = 'enqueteEnd'
        this.transitionname = `component-go${val.transition}`
      }else{*/
        if(this.compo === 'actsintro' && val.action === 'prev'){

          this.compo = 'intro';
        }else{
          this.compo = val.action === 'next' ? this.getNextSlide : this.getPrevSlide;
        }
        this.transitionname = `component-go${val.transition}`
     // }
    },
    async getScenarioRattrapage() {
      const indexCurrentEnquete = this.courses[0].modules.indexOf(this.courses[0].modules.find((m) => m.id === this.moduleIdSelected));
      const currentEnquete = this.courses[0].modules[indexCurrentEnquete]
      const [scenarios] = await parseScenarios(currentEnquete.scenarios, this.moduleIdSelected)

      this.scenarRattrapage =  scenarios
    }

  },
  computed: {
    ...mapState({
      courses: state => state.course.courses,
      enquete: state => state.enquete.enquete,
      scenarioFinished: state => state.gamestatus.scenariosFinished,
      currentModule: state => state.gamestatus.currentModule,
      activitiesFinished: state => state.gamestatus.activitiesFinished,
      actsFinished: state => state.gamestatus.actsFinished,
      currentTheme: state => state.currentTheme,
      introopen: state => state.gamestatus.introNiv2Done,
      customBg() {
        return {
          'background-image':`url(${this.enquete.thumbAndBgPic})`,
        }
      },
    }),
    getNextSlide() {
       return steps[steps.findIndex(v=> v === this.compo) + 1]
    },
    getPrevSlide() {
      return  steps[steps.findIndex(v => v === this.compo) - 1]
    },
    isQuestDone(){

      if(this.actsFinished.length === 0 || this.enquete.acts.length === 0) {
        return false;
      }
      for (const act of this.enquete.acts){
        if(!this.actsFinished.includes(act.id)){
          return false;
        }
      }
      return true
    },
    compProperties() {
      const props = {};

      props.courses = this.courses
      props.moduleId = this.moduleIdSelected
      if (this.compo === 'welcome') {
        Object.assign(props, {
          'title': 'Menez l\'enquête',
          'text': 'L’agence Presqu’Ile a besoin de vous ! Quatre situations sont dans l’impasse. Menez l’enquête pour comprendre comment en est-on arrivé là ! Ce sera ensuite à vous de trouver la solution...',
        })
        return props
      }
      else if (this.compo === 'intro') {
        if(this.rattrapage){
          props.rattrapage = true
          const moduleId = this.$store.state.gamestatus.currentModule
          props.moduleId = moduleId
        }else{
          props.rattrapage = false
        }

        return props
      }
      else if(this.compo.includes('narrative')){
        const currentNarrativeIndex = this.compo.substr(9,1)
        Object.assign(props, {
          narrative: this.enquete.narrativeIntro[currentNarrativeIndex-1]
        })
        return props
      }
      else if(this.compo === 'actsintro'){
          Object.assign(props, {
            acts: this.enquete.acts,
            title: this.enquete.title,
          })
        return props
      }else if(this.compo === 'enqueteEnd'){


        const module = (this.courses && this.courses.length > 0 && this.currentModule ? this.courses[0].modules.find( m => m.id === this.currentModule) : null)
        Object.assign(props, {
          courses: this.courses,
          enquete: this.enquete,
          moduleId: this.moduleId,
          illusId: module?.metas?.cover,
          win: this.win
        })
      }
      /*else{
        for(let [index, nar] of this.enquete.narrativeIntro.entries()){
          if (this.compo === `narrative${+index+1}`) {
            return {
              'narrative': nar
            }
          }
        }
      }*/
      return props
    }
  }
}
</script>

<style lang="scss" scoped>
.component-goup-enter-active,
.component-goup-leave-active,
.component-godown-enter-active,
.component-godown-leave-active {
  transition: transform .5s ease-in-out;

}

.component-godown-enter-from{
  transform: translateY(-100vh);

}
.component-godown-leave-to {
  transform: translateY(100vh);
}

.component-goup-enter-from{
  transform: translateY(100vh);

}
.component-goup-leave-to {
  transform: translateY(-100vh);
}

</style>
